<template>
  <div class="loader-wrapper" v-if="show">
    <div class="theme-loader">
      <div class="loader-p"></div>
    </div>
  </div>
  <Breadcrumbs main="Promotion" title="Add Promotion" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Title</label>
                <div class="col-sm-9">
                  <input v-model="name" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Code Promosi</label>
                <div class="col-sm-9">
                  <input v-model="code" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Deskripsi</label>
                <div class="col-sm-9">
                  <input v-model="deskripsi" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Action Name</label>
                <div class="col-sm-9">
                  <input v-model="actionName" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Action Link</label>
                <div class="col-sm-9">
                  <input v-model="actionLink" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Waktu Mulai Promosi</label>
                <div class="col-sm-9">
                  <input v-model="startedAt" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Waktu Selesai Promosi</label>
                <div class="col-sm-9">
                  <input v-model="endedAt" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Quota</label>
                <div class="col-sm-9">
                  <input v-model="quota" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Urutan Prioritas</label>
                <div class="col-sm-9">
                  <input v-model="priority" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Tipe Promosi</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="promotionType">
                    <option value="order_discount">Order Discount</option>
                    <option value="unit_discount">Unit Discount</option>
                    <option value="cashback">Cashback</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nilai Promosi</label>
                <div class="col-sm-9">
                  <input v-model="value" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nilai Maksimal Promosi</label>
                <div class="col-sm-9">
                  <input v-model="maxValue" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Kuota Promosi</label>
                <div class="col-sm-9">
                  <input v-model="userQuota" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Minimal Transaksi</label>
                <div class="col-sm-9">
                  <input v-model="minimalTransaction" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Tipe Nilai</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="minimalTransactionType">
                    <option value="unit">Unit</option>
                    <option value="nominal">Nominal</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Minimal Total Transaksi</label>
                <div class="col-sm-9">
                  <input v-model="minimalTotalTransaction" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Maksimal Total Transaksi</label>
                <div class="col-sm-9">
                  <input v-model="maximalTotalTransaction" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Kuota Telah Diklaim </label>
                <div class="col-sm-9">
                  <input v-model="quotaClaimed" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Coupon Image</label>
                <div class="col-sm-9">
                  <input class="form-control" accept="image/*" id="upcover" type="file" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Warna Promosi </label>
                <div class="col-sm-1">
                  <input v-model="imageDominantColor" class="form-control" type="color" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Waktu Mulai Cuopon</label>
                <div class="col-sm-9">
                  <input v-model="couponStartedAt" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Waktu Selesai Cuopon</label>
                <div class="col-sm-9">
                  <input v-model="couponEndedAt" class="form-control" type="date" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Status Promosi</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="status">
                    <option value="available">Available</option>
                    <option value="exhausted">Exhausted</option>
                  </select>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Status Promosi Disembunyikan</label>
                <div class="col-sm-9">
                  <div class="media-body icon-state switch-outline">
                    <label class="switch">
                      <input type="checkbox" v-model="isHidden" />
                      <span class="switch-state bg-success"> </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Metode Pembayaran</label>
                <div class="col-sm-9">
                  <div>
                    <ul>
                      <li>
                        <router-link
                          to="/promotion/add"
                          data-container="body"
                          data-bs-placement="top"
                          data-bs-toggle="modal"
                          data-original-title="test"
                          data-bs-target="#largeModal"
                          title="Add"
                        >
                          <vue-feather class="btn btn-xs btn-outline-primary" type="plus"> </vue-feather>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <TablePayment />
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Produk</label>
                <div class="col-sm-9">
                  <div>
                    <ul>
                      <li>
                        <router-link
                          to="/promotion/add"
                          data-container="body"
                          data-bs-placement="top"
                          data-bs-toggle="modal"
                          data-original-title="test"
                          data-bs-target="#largeModalProduct"
                          title="Add"
                        >
                          <vue-feather class="btn btn-xs btn-outline-primary" type="plus"> </vue-feather>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <TableProduct />
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Active</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectActive" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Public</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="isPublic" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-sm btn-outline-success btn-lg me-4" type="button">
                      Simpan
                    </button>
                    <router-link to="/promotion/list">
                      <button class="btn btn-sm btn-outline-primary btn-lg" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddPayment />
  <AddProduct />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import TablePayment from "./promotion_payment/promotion_payment_table.vue";
import AddPayment from "./promotion_payment/promotion_payment_select.vue";
import TableProduct from "./promotion_product/promotion_product_table.vue";
import AddProduct from "./promotion_product/promotion_product_select.vue";
export default {
  components: {
    VueFeather,
    TablePayment,
    AddPayment,
    TableProduct,
    AddProduct,
  },
  data() {
    return {
      user: [],
      meta: [],
      distributor: "",
      isLoading: false,
      tagtext: "",
      show: false,
      selectedBank: [],
      statusBank: true,
      code: "",
      name: "",
      actionName: "",
      actionLink: "",
      logoBank: "",
      deskripsi: "",
      namaPemilik: "",
      startedAt: "",
      endedAt: "",
      quota: "",
      promotionType: "",
      value: "",
      valueMax: "",
      priority: "",
      userQuota: "",
      minimalTransaction: "",
      minimalTransactionType: "",
      minimalTotalTransaction: "",
      maximalTotalTransaction: "",
      quotaClaimed: "",
      imageDominantColor: "",
      couponStartedAt: "",
      couponEndedAt: "",
      status: "",
      isHidden: false,
      paymentMethodIds: [],
      productIds: "",
      coverImage: "",
      startDate: null,
      endDate: null,
      dataDistributor: [],
      selectActive: true,
      Priority: "",
      BingkaiGambar: "#e34a4a",
      selectNeedLogin: true,
      selectPublic: true,
      content: "",
      base64Cover: null,
    };
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getDistributor();
    this.$store.state.PaymentMethods = this.paymentMethodIds;
    this.$store.state.Products = this.productIds;
  },

  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    async addImage() {
      let img = await this.convertFileToBase64(document.getElementById("upcover").files[0]);
      var dataup = new FormData();
      dataup.append("file", img);

      const data = await fetch(process.env.VUE_APP_STORAGE_URL+"api/file/upload", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer 16dd3ccc-8c63-42b2-b81b-c30a9c4ce015",
        },
        body: dataup,
      });
      const content = await data.json();
      return content;
    },

    selectBank(o, i) {
      this.logoBank = o.url;
    },

    async addItem() {
      const fileInput = document.getElementById('upcover');
      const file = fileInput.files[0];
      if (!file) {
          alert('Please select a file.');
          return;
      } 
      let img = await this.addImage();
      let dataPayment = this.$store.state.PaymentMethods;
      if (!dataPayment) {
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Tambahkan payment terlebih dahulu",
        });

        return false;
      }
      dataPayment = dataPayment.map((x) => x.id);

      let dataProduct = this.$store.state.Products;
      if (!dataProduct) {
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Tambahkan product terlebih dahulu",
        });

        return false;
      }
      dataProduct = dataProduct.map((x) => x.id);
      this.show = true;
      let data = {
        code: this.code,
        name: this.name,
        actionName: this.actionName,
        actionLink: this.actionLink,
        description: this.deskripsi,
        startedAt: this.startedAt,
        endedAt: this.endedAt,
        quota: this.quota,
        isPublic: this.isPublic,
        priority: this.priority,
        promotionType: this.promotionType,
        value: this.value,
        maxValue: this.maxValue,
        userQuota: this.userQuota,
        minimalTransaction: this.minimalTransaction,
        minimalTransactionType: this.minimalTransactionType,
        minimalTotalTransaction: this.minimalTotalTransaction,
        maximalTotalTransaction: this.maximalTotalTransaction,
        quotaClaimed: this.quotaClaimed,
        couponImage: img.data[0].url,
        imageDominantColor: this.imageDominantColor,
        couponStartedAt: this.couponStartedAt,
        couponEndedAt: this.couponEndedAt,
        status: this.status,
        isHidden: this.isHidden,
        paymentMethodIds: dataPayment,
        productIds: dataProduct,
        isActive: this.selectActive,
      };
      axios
        .post("/admin-api/promotion/add-promotion", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          if (x) {
            this.show = false;
            this.$router.replace("/promotion/list");
            this.$toast.show(" Promosi ditambahkan ", {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
          }
        }).catch((err) => {
          console.log(err);
          this.show = false;
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
    },

    getDistributor() {
      axios
        .get("/admin-api/distributor/list-distributors", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
        });
    },

    asyncDistributorSearch(q) {
      this.isLoading = true;
      axios
        .get("/admin-api/distributor/list-distributors?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
          this.isLoading = false;
        });
    },

    selectAdd(e) {
      let val = e.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
      }
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
        event.target.value = "";
      }
    },

    removeTag(index) {
      this.bannerTags.splice(index, 1);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.bannerTags.length - 1);
      }
    },
  },
};
</script>
<style>
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
.ck-editor__editable {
  min-height: 200px;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 40px;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 5px;
}

.tag-input__tag {
  height: 20px;
  color: white;
  float: left;
  font-size: 10px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 0px;
  line-height: 20px;
  padding: 0 8px;
  /* font-family: "Roboto"; */
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
