<template>
  <div
    class="modal fade bd-example-modal-fullscreen"
    id="largeModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">List Customer</h4>
          <div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-8">
                <div class="row">
                  <div class="col-6">
                    <div :class="selectedData.length > 0 ? 'btn btn-outline-success btn-xs' : ''">
                      <vue-feather
                        size="20"
                        v-show="selectedData.length > 0"
                        class=""
                        data-bs-dismiss="modal"
                        @click="save"
                        type="plus"
                      >
                      </vue-feather>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="btn btn-outline-primary btn-xs">
                      <vue-feather size="20" class="" data-bs-dismiss="modal" type="x"> </vue-feather>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mt-4 mx-4">
            <div class="input-group class-form">
              <input
                type="search"
                style="width: 100% !important"
                placeholder="Pencarian"
                class="form-control"
                @keyup.enter="cari()"
                v-model="filterQuery"
              />
            </div>
          </div>

          <div class="col-4 mt-4">
            <div class="d-flex">
              <div>
                <vue-feather class="btn btn-xs btn-outline-success me-3" @click="cari" size="20" type="search">
                </vue-feather>
              </div>
              <div>
                <vue-feather class="btn btn-xs btn-outline-primary me-3" @click="resetfilter" size="20" type="x-square">
                </vue-feather>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body" style="height: 70vh; max-height: 70vh">
          <div class="panel panel-default">
            <div class="panel-body table-add-customer">
              <table class="table table-sm table-bordered table-hover" id="tableaddcustomer">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        v-model="selectedAll"
                        class="big_checkbox"
                        id="ceklishead"
                        @click="selectAll()"
                      />&nbsp;
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">Kode Toko</th>
                    <th scope="col">Nama Toko</th>
                    <th scope="col">Kode Distributor</th>
                    <th scope="col">Nama Distributor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item">
                    <td style="display: none" id="iditem">{{ item.id }}</td>
                    <td><input class="big_checkbox" type="checkbox" @click="getRow" id="ceklis" />&nbsp;</td>
                    <td scope="row">{{ item.no }}</td>
                    <td>{{ item.customer.code }}</td>
                    <td>{{ item.customer.name }}</td>
                    <td>{{ item.distributor.code }}</td>
                    <td>{{ item.distributor.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="loader-box" v-show="isLoading == true">
                <div class="loader-2"></div>
              </div>
              <div v-show="isLoading == false && data.length == 0" class="px-4">
                <span>Tidak ada data</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-2 mx-3" v-show="data.length > 0 && isload == false">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>
              <div class="mt-2 mx-3">
                <vue-awesome-paginate
                  v-show="data.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="5"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      customer: [],
      meta: [],
      datalocal: [],
      selectedData: [],
      page: 1,
      filterQuery: "",
      No: 0,
      isLoading: false,
      perPage: 10,
      selectedAll: false,
      pageOptions: [5, 10, 20, 50, 100],
      isload: true,
    };
  },
  mounted() {
    this.getdata();
    this.$store.state.getdataCustomersForAdd = this.getdata;
  },
  methods: {
    changePage(x) {
      this.page = x;
      axios
        .get(
          "/admin-api/customer/list-customer-distributors?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.customerDistributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.data = x.data.data.customerDistributors;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.getArticleCustomer();
        });
    },

    getRow() {
      var grid = document.getElementById("tableaddcustomer");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let datalist = this.customer;
      let data = [];
      let undata = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (
          checkBoxes[i].checked &&
          datalist.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) == -1
        ) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
        if (
          checkBoxes[i].checked == false &&
          datalist.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) != -1
        ) {
          undata.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      data = data.filter((z) => z != " ");
      this.selectedData = data;
      this.removedData = undata;
    },

    resetfilter() {
      this.filterQuery = "";
      this.getdata();
    },

    cari() {
      this.isLoading = true;
      axios
        .get("/admin-api/customer/list-customer-distributors?page=1" + "&perPage=" + this.perPage + "&search=" + this.filterQuery, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.customerDistributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.data = x.data.data.customerDistributors;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.getArticleCustomer();
        });
    },

    selectAll() {
      this.selectedAll = !this.selectedAll;
      var grid = document.getElementById("tableaddcustomer");
      let data = [];
      var checkBoxes = grid.getElementsByTagName("INPUT");
      for (var i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = this.selectedAll;
        if (i != 0) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
      this.selectedData = [...this.selectedData];
      this.selectedData = [];
    },

    getArticleCustomer() {
      this.customer = [];
      if (this.$store.state.dataPromotionCustomer == undefined) {
        this.$router.replace("/promotion/list");
      } else {
        axios
          .get(
            "/admin-api/promotion/list-promotion-customer-distributors?promotionId=" +
              this.$store.state.dataPromotionCustomer.id +
              "&perPage=" +
              this.$store.state.metaDataPromotion.total,
            {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            },
          )
          .then((x) => {
            x.data.data.promotionCustomerDistributors.forEach((el) => {
              this.customer.push(el.customerDistributors);
            });
            this.fetchRow();
          });
      }
    },

    fetchRow() {
      var grid = document.getElementById("tableaddcustomer");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let count = 0;
      for (var i = 0; i < checkBoxes.length; i++) {
        if (this.customer.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) != -1) {
          checkBoxes[i].checked = true;
          checkBoxes[i].disabled = true;
          count++;
          checkBoxes[0].checked = true;
          checkBoxes[0].disabled = true;
        }
        if (count == 0) {
          checkBoxes[0].checked = false;
          checkBoxes[0].disabled = false;
        }
      }
      this.isLoading = false;
    },

    reset() {
      var grid = document.getElementById("tableaddcustomer");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      for (var i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = false;
      }
      this.selectedData = [...this.selectedData];
      this.selectedData = [];
    },

    save() {
      if (this.selectedData.length > 0) {
        let body = {
          promotionId: this.$store.state.dataPromotionCustomer.id,
          customerDistributorIds: this.selectedData,
        };
        axios
          .post("/admin-api/promotion/add-promotion-customer-distributor", body, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((x) => {
            this.$toast.show(" Customer ditambahkan ke artikel", {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 2000,
            });
            this.getdata();
            this.$store.state.getdataCustomers();
          });
      }
    },

    showper() {
      this.NoPage = 1;
      axios
        .get(
          "/admin-api/customer/list-customer-distributors?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.customerDistributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.customerDistributors;
          this.meta = x.data.data.meta;
          this.getArticleCustomer();
        });
    },

    async getdata() {
      this.isLoading = true;
      await axios
        .get("/admin-api/customer/list-customer-distributors?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.customerDistributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.data = x.data.data.customerDistributors;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isload = false;
          this.getArticleCustomer();
        });
    },
  },
};
</script>
<style>
.table-add-customer {
  max-height: 70vh;
  height: 70vh;
  overflow-x: scroll;
}
</style>
