<template>
  <div class="loader-wrapper" v-if="show">
    <div class="theme-loader">
      <div class="loader-p"></div>
    </div>
  </div>
  <Breadcrumbs main="Bank" title="Edit Bank" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="d-flex flex-row-reverse" style="height: 50px">
                <img v-show="logoBank != ''" class="img-thumbnail" :src="logoBank" />
              </div>

              <div class="mt-1 row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Distributor</label>
                <div class="col-sm-9">
                  <multiselect
                    v-model="distributor"
                    id="ajax"
                    label="name"
                    track-by="name"
                    open-direction="bottom"
                    :options="dataDistributor"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :options-limit="300"
                    :limit="3"
                    placeholder=""
                    :max-height="600"
                    :hide-selected="false"
                    @search-change="asyncDistributorSearch"
                  >
                    <span slot="noResult">Tidak Menemukan Kata kunci.</span>
                  </multiselect>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Code Bank</label>
                <div class="col-sm-9">
                  <input v-model="codeBank" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nama Bank</label>
                <div class="col-sm-9">
                  <input v-model="namaBank" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nomor Rekening</label>
                <div class="col-sm-9">
                  <input v-model="rekening" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nama Pemilik</label>
                <div class="col-sm-9">
                  <input v-model="namaPemilik" class="form-control" type="text" />
                </div>
              </div>
              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Logo Bank</label>
                <div class="col-sm-9">
                  <multiselect
                    v-show="statusBank == true"
                    v-model="selectedBank"
                    placeholder=""
                    label="nama"
                    track-by="nama"
                    :options="dataBank"
                    :option-height="104"
                    :show-labels="false"
                    @select="selectBank"
                  >
                  </multiselect>
                  <span
                    style="cursor: pointer"
                    v-show="statusBank == true"
                    @click="statusBank = !statusBank"
                    class="text-xs mt-1"
                  >
                    Bank Tidak Tersedia? Upload disini
                  </span>
                  <input v-show="statusBank == false" class="form-control" accept="image/*" id="upcover" type="file" />
                  <span
                    style="cursor: pointer"
                    v-show="statusBank == false"
                    @click="statusBank = !statusBank"
                    class="text-xs mt-1"
                    >Kembali</span
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Active</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectActive" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-sm btn-outline-success btn-lg me-4" type="button">
                      Simpan
                    </button>
                    <router-link to="/bank/list">
                      <button class="btn btn-sm btn-outline-primary btn-lg" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import Multiselect from "vue-multiselect";

export default {
  components: {
    VueFeather,
    Multiselect,
  },
  data() {
    return {
      user: [],
      meta: [],
      distributor: "",
      isLoading: false,
      tagtext: "",
      show: false,
      selectedBank: [],
      statusBank: true,
      namaBank: "",
      codeBank: "",
      logoBank: "",
      rekening: "",
      namaPemilik: "",
      coverImage: "",
      startDate: null,
      endDate: null,
      dataBank: [
        {
          nama: "BCA",
          url: "https://dev-pos.apkdemo.my.id/assets/uploads/bank_logo/bca.png",
        },
        {
          nama: "BNI",
          url: "https://dev-pos.apkdemo.my.id/assets/uploads/bank_logo/bni.png",
        },
        {
          nama: "BRI",
          url: "https://dev-pos.apkdemo.my.id/assets/uploads/bank_logo/bri.png",
        },
        {
          nama: "BTN",
          url: "https://dev-pos.apkdemo.my.id/assets/uploads/bank_logo/btn.png",
        },
        {
          nama: "Mandiri",
          url: "https://dev-pos.apkdemo.my.id/assets/uploads/bank_logo/mandiri.png",
        },
      ],
      dataDistributor: [],
      selectActive: true,
      Priority: "",
      BingkaiGambar: "#e34a4a",
      selectNeedLogin: true,
      selectPublic: true,
      content: "",
      base64Cover: null,
    };
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getdata();
    this.getTag();
  },
  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    getdata() {
      this.distributor = this.$store.state.dataBank.distributor;
      this.codeBank = this.$store.state.dataBank.code;
      this.namaBank = this.$store.state.dataBank.bankName;
      this.logoBank = this.$store.state.dataBank.bankLogo;
      this.rekening = this.$store.state.dataBank.accountNumber;
      this.namaPemilik = this.$store.state.dataBank.accountName;
      this.isActive = this.$store.state.dataBank.isActive;
    },

    cek() {
      this.imagechange = true;
    },

    getTag() {
      axios
        .get("/admin-api/banner/list-banner-tags", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let data = x.data.data.bannerTags;
          let res = data.map((c) => {
            return Object.values(c);
          });
          res = res.flat();
          res = [...new Set(res)];
          this.dataTag = res;
        });
    },

    async addImage() {
      if (this.imagechange == true) {
        let img = await this.convertFileToBase64(document.getElementById("upcover").files[0]);
        var dataup = new FormData();
        dataup.append("file", img);

        const data = await fetch(process.env.VUE_APP_STORAGE_URL+"api/file/upload", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer 16dd3ccc-8c63-42b2-b81b-c30a9c4ce015",
          },
          body: dataup,
        });
        const content = await data.json();
        return content;
      } else {
        return null;
      }
    },

    async addItem() {
      let img = null;
      if (this.statusBank == false) {
        img = await this.addImage();
      }
      let data = {
        condition: {
          bankId: this.$store.state.dataBank.id,
        },
        update: {
          distributorId: this.distributor.id,
          code: this.codeBank,
          bankLogo: this.statusBank == false ? img.data[0].url : this.logoBank,
          bankName: this.namaBank,
          accountNumber: this.rekening,
          accountName: this.namaPemilik,
          isActive: this.selectActive,
        },
      };
      axios
        .put("/admin-api/bank/edit-bank", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.$toast.show(" Bank Tersimpan ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            duration: 5000,
          });
          this.$router.replace("/bank/list");
        });
    },
    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
        event.target.value = "";
      }
    },
    removeTag(index) {
      this.bannerTags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.bannerTags.length - 1);
      }
    },
  },
};
</script>
<style>
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
.ck-editor__editable {
  min-height: 200px;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>
