<template>
  <Breadcrumbs main="Customer" title=" Customer Loyalty Program" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <div class="d-flex justify-content-between mb-0 mt-2">
                  <div class="row">
                    <div class="col-12">
                      <h5>{{ customerName }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt" v-show="items.length > 0">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Nama</th>
                  <th scope="col">gambar</th>
                  <th scope="col">Nilai</th>
                  <th scope="col">Is Active</th>
                  <th scope="col">Is Registered</th>
                </tr>
              </thead>
              <tbody v-show="isLoading == false">
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div style="width: 100px; height: 100px">
                      <img class="img-fluid img-xs img-thumbnail" :src="item.image" />
                    </div>
                  </td>
                  <td>
                    {{ item.pointSerialized }}
                  </td>
                  <td>
                    {{ item.isActive }}
                  </td>
                  <td>
                    {{ item.isRegistered }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="loader-box" v-show="isLoading == true">
              <div class="loader-2"></div>
            </div>
            <div v-show="isLoading == false && items.length == 0" class="px-4">
              <span>Tidak ada data</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";

export default {
  components: {
    VueFeather,
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      distributorIds: [],
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
      customerName: ""
    };
  },

  mounted() {
    this.user = localStorage.getItem("user");
    this.getdata();
    this.customerName = this.$store.state.dataCustomer.name;
  },
  methods: {
    getdata() {
      this.isLoading = true;
      axios
        .get("/admin-api/customer/loyalty-programs?customerId=" + this.$store.state.dataCustomer.id, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.programs.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.programs;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
