<template>
  <div id="app">
    <router-view />
    <div class="loader-wrapper" v-if="showLoader">
      <div class="theme-loader">
        <div class="loader-p"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showLoader: false,
    };
  },
  watch: {
    $route() {
      this.showLoader = true;
      setTimeout(() => {
        this.showLoader = false;
      }, 2000);
    },
  },
  created() {
    this.$store.state.client_id_client_credentials = process.env.VUE_APP_CLIENT_ID_CREDENTIALS;
    this.$store.state.client_secret_client_credentials = process.env.VUE_APP_CLIENT_SECRET_CREDENTIALS;
    this.$store.state.client_id_password = process.env.VUE_APP_CLIENT_ID;
    this.$store.state.client_secret_password = process.env.VUE_APP_CLIENT_SECRET;
    this.$store.state.client_token_password =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3ZTY0YjhiYy1lYjI5LTQ4MzgtOTZhYi1jNWZkZDMyNzQ1ZmUiLCJqdGkiOiJjNjMyMmZjMWY2OWQyZGJjYzRlNzNlZTE2NGEwZTkwNWY2NjgyMmJhMjczN2MzYWIyZjJlMWJmYmE5OWNkOTMwNzNiNDhiNzY2YWQwM2ZjZSIsImlhdCI6MTY5NDM5OTQ1MS4xOTU5MDQsIm5iZiI6MTY5NDM5OTQ1MS4xOTU5MDcsImV4cCI6MTcxMDEyNDI1MS4xODM4NSwic3ViIjoiMDFoNjJ3aDJ5Z2JjMHc5cTJraGpiNGtnNmMiLCJzY29wZXMiOlsiYWRtaW5pc3RyYXRvciJdfQ.IoPTlqIZqb8suZIWn997Dm_P5sLQVAiaT2LbvPnUgQ2Ira4kPHlrNTB2zBX5YweFNSkup6H58faZpXpOT6XbLENCZIEB6HqUUaE2DOAN7ID7bjtv1jucs6xJ25_PT67D3XmF3iJzKn_YC4DtCHWrpO15Log4PvsWp3X7UnFbBRPYKDFvh67JeyKtJDTbRO9fdRQ7d2LoSpIzh9s-YP64XcEvdtwP2M0moOsJorzrPHOx32TZ8ANzyyB9iapghZpq0ddo9mDuO5pXTTPwnrNvuoscmRbhyN0KMqtcGc6W1Tb3AxKsY845uFiklta8Gvvj3Uol73-OQA9syKWNQmPZqPHVFdWBae9vqGOOFLCEhAhRBjW8ernawLqd_-uXMwsQFXpBE9ymyT_9luFSBJkw9PpepkWReD6x2M_FTKJVXZiDXWPkr2FCs8wWD3lhH-5n1keA7KLCGNrn9Fd1uSA1-b5LbYcXj98YjZooXuoDxmvtXI76sbwE1DYHkVcc5gZB_Q6ibZ2wkBGd9BY_7813q-Xk0XHkSVNBZvIHR4b6ZCi2QKgbYcDOqHPodeBWVTycE-Le2GqjxDifcSrmLd7RX6ErnJuUfO85tWuwLuPgRp7XchSWx_9ZZyUxoR7zKE3VKkhgOFeBQT9_BQOUKtG8p0AUzg57HRqRp_MdRM2bJeA";
    this.keygen();
  },
  methods: {
    async keygen() {
      let body = {
        grantType: "client_credentials",
        clientId: this.$store.state.client_id_client_credentials,
        clientSecret: this.$store.state.client_secret_client_credentials,
      };
      const x = await axios.post("/admin-api/authentication/token", body);
      axios.defaults.headers.common["Authorization"] = "Bearer " + x.data.data.accessToken;
      this.$store.state.token = x.data.data;
    },
  },
};
</script>
<style lang="scss"></style>
<style>
.paginate-buttons {
  width: 35px !important;
}
.multiselect__tags {
  color: #717171 !important;
  font-weight: 50 !important;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px !important;
}
</style>
