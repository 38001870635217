<template>
  <div v-if="isVisible" class="modal fade show" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn btn-lg btn-default close" @click="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p v-if="isButtonConfirm">{{ message }}</p>
          <textarea class="form-control" rows="20" v-if="!isButtonConfirm">{{ message }}</textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">
            <span v-if="isButtonConfirm">Cancel</span>
            <span v-if="!isButtonConfirm">Close</span>
          </button>
          <button type="button" class="btn btn-primary" v-if="isButtonConfirm" @click="confirm">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Confirmation"
    },
    message: {
      type: String,
      default: "Are you sure you want to proceed?"
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    isButtonConfirm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("update:isVisible", false);
    },
    confirm() {
      this.$emit("confirmed", this.item);
      this.close();
    }
  }
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>