const menu = [
  {
    headTitle1: "Mastering Partner",
    type: "headtitle",
  },
  {
    title: "Distributor",
    icon: "user",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/distributor/list",
  },
  {
    title: "User",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        path: "/user/list",
        title: "List User",
        type: "link",
      },
      {
        path: "/user-management/list",
        title: "User Management",
        type: "link",
      },
      {
        path: "/user-temp/list",
        title: "User Temp",
        type: "link",
      },
      {
        path: "/user-otp/list",
        title: "User OTP",
        type: "link",
      },
    ],
  },
  {
    title: "Salesman",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      {
        path: "/partner/salesman",
        title: "Salesman List",
        type: "link",
      },
      {
        path: "/partner/salesman-customer",
        title: "Salesman Customer List",
        type: "link",
      },
    ],
  },
];

export default menu;
