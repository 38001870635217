<template>
  <div class="loader-wrapper" v-if="show">
    <div class="theme-loader">
      <div class="loader-p"></div>
    </div>
  </div>
  <Breadcrumbs main="Features" title="Edit Features" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Code</label>
                <div class="col-sm-9">
                  <input v-model="code" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Name</label>
                <div class="col-sm-9">
                  <input v-model="name" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Is Active</label>
                <div class="col-sm-9">
                  <div class="media-body icon-state switch-outline">
                    <label class="switch">
                      <input type="checkbox" v-model="selectActive" />
                      <span class="switch-state bg-success"> </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-sm btn-outline-success btn-lg me-4" type="button">
                      Simpan
                    </button>
                    <router-link to="/features/list">
                      <button class="btn btn-sm btn-outline-primary btn-lg" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";

export default {
  components: {
    VueFeather
  },
  data() {
    return {
      user: [],
      meta: [],
      isLoading: false,
      tagtext: "",
      show: false,
      code: "",
      name: "",
      selectActive: true,
    };
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getdata();
  },
  methods: {
    getdata() {
      this.code = this.$store.state.features.featureCode;
      this.name = this.$store.state.features.featureName;
      this.selectActive = this.$store.state.features.isActive;
    },

    async addItem() {
      let data = {
        condition: {
          featureId: this.$store.state.features.id,
        },
        update: {
          code: this.code,
          name: this.name,
          isActive: this.selectActive,
        },
      };
      axios
        .put("/admin-api/features/edit-features", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.$toast.show(" Features berhasil diubah ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            duration: 5000,
          });
          this.$router.replace("/features/list");
        });
    },
  },
};
</script>
<style>
.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>
