<template>
  <div class="loader-wrapper" v-if="show">
    <div class="theme-loader">
      <div class="loader-p"></div>
    </div>
  </div>
  <Breadcrumbs main="Product Brand" title="Edit Product Brand" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Code Brand</label>
                <div class="col-sm-9">
                  <input v-model="codeBrand" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nama Brand</label>
                <div class="col-sm-9">
                  <input v-model="namaBrand" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Deskripsi</label>
                <div class="col-sm-9">
                  <input v-model="deskripsiBrand" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Gambar</label>
                <div class="col-sm-9">
                  <img :src="localImage" class="img-thumbnail mb-2" />
                  <input class="form-control" @change="cek" accept="image/*" id="upcover" type="file" />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Active</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectActive" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-sm btn-outline-success btn-lg me-4" type="button">
                      Simpan
                    </button>
                    <router-link to="/product-brand/list">
                      <button class="btn btn-sm btn-outline-primary btn-lg" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import Multiselect from "vue-multiselect";

export default {
  components: {
    VueFeather,
    Multiselect,
  },
  data() {
    return {
      user: [],
      meta: [],
      isLoading: false,
      show: false,
      localImage: null,
      gambarBrand: null,
      statusBrand: true,
      namaBrand: "",
      codeBrand: "",
      namaBrand: "",
      deskripsiBrand: "",
      coverImage: "",
      selectActive: true,
      imagechange: false,
      base64Cover: null,
    };
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getData();
  },

  methods: {
    getData() {
      this.codeBrand = this.$store.state.dataBrand.code;
      this.namaBrand = this.$store.state.dataBrand.name;
      this.deskripsiBrand = this.$store.state.dataBrand.description;
      this.localImage = this.$store.state.dataBrand.image;
      this.isActive = this.$store.state.dataBrand.isActive;
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    cek() {
      this.imagechange = true;
    },

    async addImage() {
      if (this.imagechange == true) {
        let img = await this.convertFileToBase64(document.getElementById("upcover").files[0]);
        var dataup = new FormData();
        dataup.append("file", img);

        const data = await fetch(process.env.VUE_APP_STORAGE_URL+"api/file/upload", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer 16dd3ccc-8c63-42b2-b81b-c30a9c4ce015",
          },
          body: dataup,
        });
        const content = await data.json();
        return content;
      } else {
        return null;
      }
    },

    selectBrand(o, i) {
      this.logoBrand = o.url;
    },

    async addItem() {
      this.show = true;
      let img = await this.addImage();
      let data = {
        condition: {
          productBrandId: this.$store.state.dataBrand.id,
        },
        update: {
          code: this.codeBrand,
          name: this.namaBrand,
          image: img == null ? this.$store.state.dataBrand.image : img.data[0].url,
          description: this.deskripsiBrand,
          isActive: this.selectActive,
        },
      };
      axios
        .put("/admin-api/product-brand/edit-product-brand", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          if (x) {
            this.show = false;
            this.$router.replace("/product-brand/list");
            this.$toast.show(" Brand disimpan ", {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
          }
        });
    },

    selectAdd(e) {
      let val = e.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
      }
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
        event.target.value = "";
      }
    },

    removeTag(index) {
      this.bannerTags.splice(index, 1);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.bannerTags.length - 1);
      }
    },
  },
};
</script>
<style>
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
.ck-editor__editable {
  min-height: 200px;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 40px;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 5px;
}

.tag-input__tag {
  height: 20px;
  color: white;
  float: left;
  font-size: 10px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 0px;
  line-height: 20px;
  padding: 0 8px;
  /* font-family: "Roboto"; */
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
