const menu = [
  {
    headTitle1: "Mastering Information",
    type: "headtitle",
  },
  {
    title: "Article",
    icon: "edit",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/article/list",
  },
  {
    title: "Banner",
    icon: "edit",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/banner/list",
  },
  {
    title: "Bank",
    icon: "credit-card",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/bank/list",
  },
  {
    title: "Promotion",
    icon: "radio",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/promotion/list",
  },
];

export default menu;
