<template>
  <div class="container-fluid">
    <LoadingModal :show="isLoadingModal" />
    <div class="row">
      <div class="col-sm-12">
        <div class="row mt-4">
          <div class="col-sm-12 text-center">
            <img class="img-fluid for-light" src="@/assets/images/logo/favicon.png" width="120" alt="V3" />
            <h1 class="text-success">Query Interface</h1>
          </div>
        </div>
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">Token</label>
          <div class="col-sm-8">
            <input v-model="token" class="form-control" type="text" />
          </div>
          <div class="col-sm-2">
            <button @click="saveToken()" class="btn btn-sm btn-primary btn-lg" type="button">
              Simpan
            </button>
          </div>
        </div>
        
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">List DB</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="selectedDb" @change="searchSchema()">
              <option v-for="(item) in listDb" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">List Schema</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="selectedSchema" @change="searchTable()">
              <option v-for="(item) in listSchema" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">List Table</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="selectedTable">
              <option v-for="(item) in listTable" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">Method</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="selectedMethod">
              <option value="select">select</option>
              <option value="insert">insert</option>
              <option value="update">update</option>
              <option value="alter">alter</option>
              <option value="create">create</option>
            </select>
          </div>
        </div>
        
        <div class="row mb-4 g-3">
          <label class="col-sm-2 col-form-label text-end">Raw Query</label>
          <div class="col-sm-8">
            <textarea
            class="form-control"
            style="height: 200px"
            v-model="rawQuery"
            ></textarea>
          </div>
          <div class="col-sm-2">
            <button @click="executeQuery()" class="btn btn-sm btn-warning btn-lg" type="button">
              Execute
            </button>
          </div>
        </div>
        
        <div class="row mt-6">
          <div class="col-md-9 offset-2" v-if="data.length">
            <h3 class="mb-3">Query Result</h3>

            <button @click="exportToExcel" class="btn btn-sm btn-success btn-lg mb-3" type="button">
              Export to Excel
            </button>
            
            <div class="mb-3">
              <label for="search" class="form-label">Search</label>
              <input
              id="search"
              v-model="searchQuery"
              type="text"
              class="form-control"
              placeholder="Search..."
              />
            </div>
            
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <!-- <tr>
                    <th v-for="(header, index) in headers" :key="index">
                      {{ header }}
                    </th>
                  </tr> -->
                  <tr>
                    <th
                      v-for="(header, index) in headers"
                      :key="index"
                      @click="sortTable(header)"
                      style="cursor: pointer;">
                      {{ header }}
                      <span v-if="sortKey === header">
                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-for="(item, index) in paginatedData" :key="index">
                    <td v-for="(header, i) in headers" :key="i">
                      {{ item[header] }}
                    </td>
                  </tr> -->
                  <tr v-for="(item, index) in paginatedData" :key="index">
                    <td v-for="(header, i) in headers" :key="i">
                      {{ item[header] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <nav v-if="totalPages > 1">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="changePage(currentPage - 1)">Previous</button>
                </li>
                <li class="page-item" :class="{ active: currentPage === 1 }">
                  <button class="page-link" @click="changePage(1)">1</button>
                </li>
                <li v-if="showLeftDots" class="page-item disabled">
                  <span class="page-link">...</span>
                </li>
                <li
                  class="page-item"
                  v-for="page in middlePages"
                  :key="page"
                  :class="{ active: currentPage === page }"
                >
                  <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li v-if="showRightDots" class="page-item disabled">
                  <span class="page-link">...</span>
                </li>
                <li class="page-item" :class="{ active: currentPage === totalPages }">
                  <button class="page-link" @click="changePage(totalPages)">{{ totalPages }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="page-link" @click="changePage(currentPage + 1)">Next</button>
                </li>
              </ul>
            </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<AddPayment />
<AddProduct />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import LoadingModal from '@/components/modal_loading.vue';
import * as XLSX from "xlsx";

export default {
  components: {
    VueFeather,
    LoadingModal
  },
  data() {
    return {
      user: [],
      meta: [],
      isLoadingModal: false,
      token: "",
      selectedDb: "",
      listDb: [],
      selectedSchema: "",
      listSchema: [],
      selectedTable: "",
      listTable: [],
      selectedMethod: "",
      rawQuery: "",
      
      data: [],
      headers: [],
      searchQuery: "",
      currentPage: 1, 
      itemsPerPage: 10,
      maxVisibleMiddlePages: 3,
      sortKey: "",
      sortOrder: "asc"
    };
  },
  computed: {
    filteredData() {
      if (!this.searchQuery) return this.data;
      const query = this.searchQuery.toLowerCase();
      return this.data.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(query)
        )
      );
    },
    
    sortedData() {
      if (!this.sortKey) return this.filteredData;
      return [...this.filteredData].sort((a, b) => {
        const valueA = a[this.sortKey];
        const valueB = b[this.sortKey];
        if (valueA < valueB) return this.sortOrder === "asc" ? -1 : 1;
        if (valueA > valueB) return this.sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    },
    
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    },
    
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    
    showLeftDots() {
      return this.currentPage > this.maxVisibleMiddlePages + 1;
    },
    
    showRightDots() {
      return this.currentPage < this.totalPages - this.maxVisibleMiddlePages;
    },
    
    middlePages() {
      const start = Math.max(
        2,
        this.currentPage - Math.floor(this.maxVisibleMiddlePages / 2)
      );
      const end = Math.min(this.totalPages - 1, start + this.maxVisibleMiddlePages - 1);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  mounted() {
    this.user = localStorage.getItem("token");
  },
  
  methods: {
    async saveToken() {
      this.isLoadingModal = true;
      this.selectedDb = "";
      this.listDb = [];
      this.selectedSchema = "";
      this.listSchema = [];
      this.selectedTable = "";
      this.listTable = [];
      axios
      .get("/admin-api/su-tools/list-d", {
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("token"),
          'auth-key': this.token
        },
      })
      .then((x) => {
        this.listDb = x.data.data;
        this.isLoadingModal = false;
      }).catch((err) => {
        this.isLoadingModal = false;
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Syntax",
        });
      });
    },
    
    async searchSchema() {
      this.isLoadingModal = true;
      this.selectedSchema = "";
      this.listSchema = [];
      this.selectedTable = "";
      this.listTable = [];
      let data = {
        databaseName: this.selectedDb,
      };
      axios
      .get("/admin-api/su-tools/list-sch", {
        params: data,
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("token"),
          'auth-key': this.token
        },
      })
      .then((x) => {
        this.listSchema = x.data.data;
        this.isLoadingModal = false;
      }).catch((err) => {
        this.isLoadingModal = false;
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Syntax",
        });
      });
    },
    
    async searchTable() {
      this.isLoadingModal = true;
      this.selectedTable = "";
      this.listTable = [];
      let data = {
        databaseName: this.selectedDb,
        schemaName: this.selectedSchema
      };
      axios
      .get("/admin-api/su-tools/list-tbl", {
        params: data,
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("token"),
          'auth-key': this.token
        },
      })
      .then((x) => {
        this.listTable = x.data.data;
        this.isLoadingModal = false;
      }).catch((err) => {
        this.isLoadingModal = false;
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Syntax",
        });
      });
    },
    
    async executeQuery() {
      let data = {
        databaseName: this.selectedDb,
        method: this.selectedMethod,
        query: this.rawQuery
      };
      axios
      .post("/admin-api/su-tools/raw-q", data, {
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("token"),
          'auth-key': this.token
        },
      })
      .then((x) => {
        this.data = x.data.data;
        if (this.data.length> 0) {
          this.headers = Object.keys(this.data[0]);
        }
        
        this.isLoadingModal = false;
      }).catch((err) => {
        this.isLoadingModal = false;
        this.$swal({
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          type: "error",
          title: "Syntax Error",
        });
      });
    },
    
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    
    sortTable(header) {
      if (this.sortKey === header) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = header;
        this.sortOrder = "asc";
      }
    },

    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.filteredData);
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Data");

      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 10); 
      const formattedTime = now.toTimeString().slice(0, 8).replace(/:/g, "-");

      // Combine date and time for the filename
      const filename = `query_results_${formattedDate}_${formattedTime}.xlsx`;
      XLSX.writeFile(wb, filename);
    },
  },
};
</script>
<style>
.table-container {
  overflow-x: auto; 
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
