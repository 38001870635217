<template>
  <Breadcrumbs main="User Management" title=" User List" />
  <div class="container-fluid">
    <LoadingModal :show="isLoadingModal" />
    <ModalEditUser
      ref="confirmModal"
      :isVisible="isModalVisible"
      :item="selectedItem"
      modalId="processConfirmationModal"
      title="Modal Edit User"
      @confirmed="syncDistributor"
      @update:isVisible="isModalVisible = $event"
    />
    <ConfirmationModal
      ref="modalConfirmation"
      :isVisible="isModalConfirmation"
      :item="selectedItem"
      modalId="processConfirmationModal"
      :title="tittleModal"
      :message="messageModal"
      :isButtonConfirm="isButtonConfirm"
      @confirmed="generateToken"
      @update:isVisible="isModalConfirmation = $event"
    />
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <div class="d-flex justify-content-between mb-0 mt-2">
                  <div class="d-flex">
                    <div class="me-2">
                      <div class="input-group class-form">
                        <input
                          type="search"
                          style="width: 100% !important; height: 45px"
                          placeholder="Pencarian"
                          class="form-control"
                          @keyup.enter="cari()"
                          v-model="filterQuery"
                        />
                      </div>
                    </div>

                    <div class="mx-2 mt-2">
                      <div class="d-flex">
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-success me-3"
                            @click="filter"
                            size="20"
                            type="search"
                          >
                          </vue-feather>
                        </div>
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-primary me-3"
                            @click="reset"
                            size="20"
                            type="x-square"
                          >
                          </vue-feather>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 mt-2">
                <div class="position-absolute top-0 end-0">
                  <ul>
                    <li v-show="false">
                      <router-link to="/bank/add" data-container="body" data-bs-placement="top" title="Add">
                        <vue-feather class="btn btn-xs btn-outline-primary" type="plus"> </vue-feather>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">Kode Customer</th>
                  <th scope="col">Kode Distributor</th>
                  <th scope="col">Email</th>
                  <th scope="col">HP</th>
                  <th scope="col">Nama Depan</th>
                  <th scope="col">Nama Belakang</th>
                  <th scope="col">Email Verified</th>
                  <th scope="col">Phone Verified</th>
                  <th scope="col">Status Akun</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
              <tbody v-show="isLoading == false">
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.customer ? item.customer.code : '-' }}</td>
                  <td>{{ item.distributor ? item.distributor.code : "-" }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.firstName }}</td>
                  <td>{{ item.lastName }}</td>
                  <td>{{ item.isEmailVerified }}</td>
                  <td>{{ item.isPhoneVerified }}</td>
                  <td>{{ item.isActive }}</td>
                  <td>
                    <ul>
                      <li class="mb-1 mx-1">
                        <vue-feather class="btn btn-xs btn-outline-primary" size="18" type="edit" @click="openModalEdit(item)"></vue-feather>
                      </li>
                      <li v-show="item.isActive" class="mb-1 mx-1">
                        <vue-feather class="btn btn-xs btn-outline-primary" size="18" type="lock" @click="openModalGenerateToken(item)"></vue-feather>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="loader-box" v-show="isLoading == true">
              <div class="loader-2"></div>
            </div>
            <div v-show="isLoading == false && items.length == 0" class="px-4">
              <span>Tidak ada data</span>
            </div>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate
                  v-show="items.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="meta.perPage"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeleteBank />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DeleteBank from "@/components/bank/bank_delete.vue";
import ModalEditUser from "./modal_edit_user.vue";
import EventBus from '@/eventBus.js';
import LoadingModal from './modal_loading.vue';
import ConfirmationModal from './modal_confirmation.vue';

export default {
  components: {
    VueFeather,
    DeleteBank,
    ModalEditUser,
    LoadingModal,
    ConfirmationModal
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
      isModalVisible: false,
      isLoadingModal: false,
      isModalConfirmation: false,
      isButtonConfirm: false,
      tittleModal: "",
      messageModal: ""
    };
  },
  created() {
    EventBus.on('open-loading', this.handleOpenModalLoading);
    EventBus.on('refresh-data', this.handleRefreshData);
  },
  mounted() {
    this.user = localStorage.getItem("user");
    this.getdata();
    this.$store.state.getdataBank = this.getdata;
  },
  methods: {
    getdata() {
      this.isLoading = true;
      axios
        .get("/admin-api/users/list-users?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    filter() {
      this.isLoading = true;
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/users/list-users?perPage=" +
            this.perPage +
            "&" +
            valTag[0] +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
          this.isLoading = false;
        });
    },

    reset() {
      this.isLoading = true;
      this.valueTag = [];
      this.filterQuery = "";
      this.filterPublik = "";
      this.filterStatus = "";
      this.filter();
      this.filterOpt = false;
      this.isLoading = false;
    },

    cari() {
      this.isLoading = true;
      this.page = 1;
      axios
        .get("/admin-api/users/list-users?page=1" + "&perPage=" + this.perPage + "&search=" + this.filterQuery, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    changePage(x) {
      this.isLoading = true;
      this.page = x;
      axios
        .get(
          "/admin-api/users/list-users?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },

    showper() {
      this.isLoading = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/users/list-users?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.users.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.users;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    openModalEdit(item) {
      this.selectedItem = item;
      this.isModalVisible = true;
      EventBus.emit('open-modal', item);
    },

    openModalGenerateToken(item) {
      this.selectedItem = item;
      this.isModalConfirmation = true;
      this.isButtonConfirm = true;
      this.tittleModal = "Konfirmasi";
      this.messageModal = "Apakah Anda yakin untuk menggenerate token ?";
    },

    async generateToken() {
      this.isLoading = true;
      let data = {
        username: this.selectedItem.username
      };
      axios
        .post("/admin-api/customer/generate-user-token", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.isModalConfirmation = true;
          this.isButtonConfirm = false;
          this.tittleModal = "Generate Token Berhasil";
          this.messageModal = x.data.data.accessToken;
          this.getdata();
        }).catch((err) => {
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: "Gagal Generate Token User",
        });
      });
    },

    handleOpenModalLoading(sts) {
      this.isLoadingModal = sts;
    },
    
    handleRefreshData() {
      this.filter();
    }
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
