<template>
  <div v-if="isVisible" class="modal fade show" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn btn-lg btn-default close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <form id="form_input">
                <input type="hidden" name="userId" id="userId" v-model="params.userId">
                <div class="form theme-form">
                  <div class="row mb-4 g-3">
                    <label class="col-sm-2 col-form-label text-start">Password</label>
                    <div class="col-sm-8">
                      <div style="display: flex; align-items: center;">
                        <input v-model="params.password" :disabled="isDisabled('password')" name="password" class="form-control" :type="showPassword ? 'text' : 'password'" />
                        <button type="button" class="btn btn-xs btn-outline-success" :hidden="isDisabled('password')" @click="togglePassword()" style="margin-left: 8px;">
                          {{ showPassword ? 'Hide' : 'Show' }}
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <vue-feather class="btn btn-xs btn-outline-primary" :hidden="isDisabled('editpassword')" size="18" type="edit" @click="openModalEdit(item,'password')"></vue-feather>
                      <div :hidden="isDisabled('password')">
                        <vue-feather class="btn btn-xs btn-outline-success" :disabled="isDisabled('password')" size="18" type="save" @click="save(item,'password')"></vue-feather>
                        <vue-feather class="btn btn-xs btn-outline-light" style="margin-left: 10px;" :disabled="isDisabled('password')" size="18" type="x" @click="close(item,'password')"></vue-feather>
                      </div>
                      <span v-if="isDisabled('loadingPassword')">
                        <i class="fa fa-spinner fa-spin"></i> Loading...
                      </span>
                    </div>
                  </div>
                  
                  <div class="row mb-4 g-3">
                    <label class="col-sm-2 col-form-label text-start">Email</label>
                    <div class="col-sm-8">
                      <input v-model="params.email" :disabled="isDisabled('email')" name="email" class="form-control" type="text" />
                    </div>
                    <div class="col-sm-2">
                      <vue-feather class="btn btn-xs btn-outline-primary" :hidden="isDisabled('editemail')" size="18" type="edit" @click="openModalEdit(item,'email')"></vue-feather>
                      <div :hidden="isDisabled('email')">
                        <vue-feather class="btn btn-xs btn-outline-success" :disabled="isDisabled('email')" size="18" type="save" @click="save(item,'email')"></vue-feather>
                        <vue-feather class="btn btn-xs btn-outline-light" style="margin-left: 10px;" :disabled="isDisabled('email')" size="18" type="x" @click="close(item,'email')"></vue-feather>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row mb-4 g-3">
                    <label class="col-sm-2 col-form-label text-start">No HP</label>
                    <div class="col-sm-8">
                      <input v-model="params.noHp" :disabled="isDisabled('noHp')" name="phone" class="form-control" type="text" />
                    </div>
                    <div class="col-sm-2">
                      <vue-feather class="btn btn-xs btn-outline-primary" :hidden="isDisabled('editnoHp')" size="18" type="edit" @click="openModalEdit(item,'noHp')"></vue-feather>
                      <div :hidden="isDisabled('noHp')">
                        <vue-feather class="btn btn-xs btn-outline-success" :disabled="isDisabled('noHp')" size="18" type="save" @click="save(item,'noHp')"></vue-feather>
                        <vue-feather class="btn btn-xs btn-outline-light" style="margin-left: 10px;" :disabled="isDisabled('noHp')" size="18" type="x" @click="close(item,'noHp')"></vue-feather>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row mb-4 g-3">
                    <label class="col-sm-2 col-form-label text-start">Status Phone Verified</label>
                    <div class="col-sm-8">
                      <select v-model="params.statusPhone" :disabled="isDisabled('statusPhone')" name="statusPhone" class="form-control">
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                    <div class="col-sm-2">
                      <vue-feather class="btn btn-xs btn-outline-primary" :hidden="isDisabled('editstatusPhone')" size="18" type="edit" @click="openModalEdit(item,'statusPhone')"></vue-feather>
                      <div :hidden="isDisabled('statusPhone')">
                        <vue-feather class="btn btn-xs btn-outline-success" :disabled="isDisabled('statusPhone')" size="18" type="save" @click="save(item,'statusPhone')"></vue-feather>
                        <vue-feather class="btn btn-xs btn-outline-light" style="margin-left: 10px;" :disabled="isDisabled('statusPhone')" size="18" type="x" @click="close(item,'statusPhone')"></vue-feather>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row mb-4 g-3">
                    <label class="col-sm-2 col-form-label text-start">Status Email Verified</label>
                    <div class="col-sm-8">
                      <select v-model="params.statusEmail" :disabled="isDisabled('statusEmail')" name="statusEmail" class="form-control">
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                    <div class="col-sm-2">
                      <vue-feather class="btn btn-xs btn-outline-primary" :hidden="isDisabled('editstatusEmail')" size="18" type="edit" @click="openModalEdit(item,'statusEmail')"></vue-feather>
                      <div :hidden="isDisabled('statusEmail')">
                        <vue-feather class="btn btn-xs btn-outline-success" :disabled="isDisabled('statusEmail')" size="18" type="save" @click="save(item,'statusEmail')"></vue-feather>
                        <vue-feather class="btn btn-xs btn-outline-light" style="margin-left: 10px;" :disabled="isDisabled('statusEmail')" size="18" type="x" @click="close(item,'statusEmail')"></vue-feather>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from '@/eventBus.js';

export default {
  data() {
    return {
      showPassword: false,
      disabledStates: {
        email: true,
        password: true,
        noHp: true,
        statusPhone: true,
        statusEmail: true,
        editpassword: false,
        loadingpassword: false,
        editemail: false,
        editnoHp: false,
        editstatusPhone: false,
        editstatusEmail: false,
      },
      params: {
        password: "",
        email: "",
        noHp: "",
        statusPhone: "",
        statusEmail: "",
        userId: ""
      }
    };
  },
  created() {
    EventBus.on('open-modal', this.handleOpenModal);
  },
  props: {
    title: {
      type: String,
      default: "Confirmation"
    },
    message: {
      type: String,
      default: "Are you sure you want to proceed?"
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:isVisible", false);
    },
    handleOpenModal(item) {
      this.isModalVisible = true;
      this.params.email = item.email;
      this.params.noHp = item.phone;
      this.params.statusPhone = item.isPhoneVerified;
      this.params.statusEmail = item.isEmailVerified;
      this.params.userId = item.id;
    },
    isDisabled(formKey) {
      return this.disabledStates[formKey];
    },
    toggleDisabled(formKey) {
      if (formKey == "password" && this.disabledStates[formKey] == true) {
        this.params.password = "";
      } else {
        this.params.password = "password";
      }
      
      this.disabledStates[formKey] = !this.disabledStates[formKey];
      this.disabledStates['edit'+formKey] = !this.disabledStates['edit'+formKey];
      this.disabledStates['container'+formKey] = !this.disabledStates['container'+formKey];
      this.disabledStates['save'+formKey] = !this.disabledStates['save'+formKey];
      this.disabledStates['close'+formKey] = !this.disabledStates['close'+formKey];
    },
    openModalEdit(item,type) {
      this.toggleDisabled(type);
    },
    save(item, type) {
      EventBus.emit('open-loading', true);
      
      let myForm = document.getElementById('form_input');
      const formEntries = new FormData(myForm).entries();
      const data = Object.assign(...Array.from(formEntries, ([x,y]) => ({[x]:y})));
      
      if (type == "password") {
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

        if (!passwordPattern.test(this.password)) {
          EventBus.emit('open-loading', false);
          this.$swal({
              icon: "error",
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              type: "error",
              title: "Password must be at least 8 characters long and contain both letters and numbers.",
            });
        } else {
          axios
          .put("/admin-api/users/edit-user", data, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((x) => {
            EventBus.emit('open-loading', false);
            EventBus.emit('refresh-data');
            this.toggleDisabled(type);
            this.$toast.show(" Berhasil Update User ", {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
          }).catch((err) => {
            EventBus.emit('open-loading', false);
            EventBus.emit('refresh-data');
            // this.toggleDisabled(type);
            this.$swal({
              icon: "error",
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              type: "error",
              title: err.response.data.message,
            });
          });
        }
      } else {
        axios
        .put("/admin-api/users/edit-user", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          EventBus.emit('open-loading', false);
          EventBus.emit('refresh-data');
          this.toggleDisabled(type);
          this.$toast.show(" Berhasil Update User ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            duration: 5000,
          });
        }).catch((err) => {
          EventBus.emit('open-loading', false);
          EventBus.emit('refresh-data');
          // this.toggleDisabled(type);
          this.$swal({
            icon: "error",
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            type: "error",
            title: err.response.data.message,
          });
        });
      }
    },
    close(item, type) {
      this.toggleDisabled(type);
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  beforeUnmount() {
    EventBus.off('open-modal', this.handleOpenModal);
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>